<template>
    <div class="manage-alert" @click="onMaskClick">
        <div class="manage-alert-card" @click="onCardClick($event)">
            <div class="manage-alert-card-top">
                <p class="manage-alert-card-top-title">{{ title }}</p>
                <button class="manage-alert-card-top-button" @click="onDeleteButtonClick">
                    <img src="@imgs/manage-alert-delete.png">
                </button>
            </div>
            <slot name="content"></slot>
            <div class="manage-alert-card-bottom">
                <button class="manage-alert-card-bottom-item left" @click="onLeftButtonClick">取消</button>
                <button class="manage-alert-card-bottom-item right" @click="onRightButtonClick">确定</button>
            </div>
            <slot name="bottom"></slot>
        </div>
    </div>
</template>
  
<script>
export default {
    model: {
        prop: "isShowAlert",
        event: "onIsShowAlertChanged"
    },

    props: {
        isShowAlert: Boolean,
        title: String
    },


    methods: {
        onCardClick(event) {
            event.stopPropagation(); //停止穿透
        },

        onMaskClick() {
            this.$emit("onIsShowAlertChanged", false);
        },

        onDeleteButtonClick() {
            this.$emit("onIsShowAlertChanged", false)
        },

        onLeftButtonClick() {
            this.$emit("onIsShowAlertChanged", false)
        },

        onRightButtonClick() {
            this.$emit("onRightButtonClick")
        }
    },
};
</script>
  
<style lang="scss" scoped>


.manage-alert {
    @include z-index-max;
    background-color: rgba($color: #666666, $alpha: 0.2);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-card {
        background-color: white;
        width: rem(820);
        border-radius: rem(4);

        &-top {
            border-bottom: rem(1) solid #E8E8E8;
            height: rem(54);
            box-sizing: border-box;
            padding: rem(0) rem(24);
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-title{
                color: #666666;
                font-size: rem(16);
            }

            &-button{
                width: rem(20);
                height: rem(20);

                >img{
                    width: 100%;
                    height: 100%;
                }
            }
        }


        &-bottom {
            border-top: 1px solid #E8E8E8;
            height: rem(57);
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &-item {
                font-size: rem(14);
                border-radius: rem(1);
                width: rem(65);
                height: rem(32);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.left {
    color: #666666;
    background-color: white;
    border: 1px solid #E8E8E8;
    margin-right: rem(10);
}

.right {
    color: white;
    background-color: #325FAC;
    border: 1px solid #325FAC;
    margin-right: rem(16);

}
</style>
