<template>
    <manage-alert v-model="isShowAlertTemp" :title="title" @onRightButtonClick="onRightButtonClick">
        <template #content>
            <div class="manage-alert-add-organization">
                <div class="manage-alert-add-organization-left">
                    <div class="manage-alert-add-organization-left-input">
                        <p class="manage-alert-add-organization-left-input-key">
                            <span class="manage-alert-add-organization-left-input-key-star">*</span>
                            机构名称：
                        </p>
                        <border-input class="manage-alert-add-organization-left-input-value"
                            :initalValue="hospitalTitle" @onInput="onCompanyInputInput"></border-input>
                    </div>
                    <div class="manage-alert-add-organization-left-input">
                        <p class="manage-alert-add-organization-left-input-key">
                            <span class="manage-alert-add-organization-left-input-key-star">*</span>
                            机构睡眠报告抬头名称：
                        </p>
                        <border-input class="manage-alert-add-organization-left-input-value" :initalValue="reportTitle"
                            @onInput="onReportInputInput"></border-input>
                    </div>
                    <div class="manage-alert-add-organization-left-bottom">
                        <upload-unit iconKey="机构Logo" :initialIconURL="initialHospitalLogo"
                            @onUploadSucceed="onCompanyLogoUploadSucceed"></upload-unit>
                        <upload-unit iconKey="睡眠报告Logo" :initialIconURL="initialReportLogo"
                            @onUploadSucceed="onReportLogoUploadSucceed"></upload-unit>
                    </div>
                </div>
                <div class="manage-alert-add-organization-right">
                    <pick-unit class="manage-alert-add-organization-right-standard" titleKey="儿童报告诊断标准" :width="286"
                        :initialPickedIndex="currentPickedStandardIndex"
                        @onListItemClick="onStandardListItemClick"></pick-unit>
                    <pick-unit class="manage-alert-add-organization-right-suggestion" titleKey="报告评估建议" :width="286"
                        :initialPickedIndex="currentPickedSuggestionIndex"
                        @onListItemClick="onSuggestionListItemClick"></pick-unit>
                    <div class="manage-alert-add-organization-right-forbidden-status">
                        <p class="manage-alert-add-organization-left-input-key">
                            <span class="manage-alert-add-organization-left-input-key-star">*</span>
                            状态：
                        </p>
                        <switch-picker :initialIsPickRight="forbiddenStatus == 0" left="启用" right="禁用"
                            @onPick="onForbiddenStatusPicked"></switch-picker>
                    </div>
                </div>
            </div>
        </template>
    </manage-alert>
</template>

<script>
import ManageAlert from "@c/manage/manage-alert/manage-alert.vue";
import BorderInput from "@c/common/border-input.vue";
import UploadUnit from "@c/setting/organization-configuration/edit/upload-unit/upload-unit.vue";
import PickUnit from "@c/setting/organization-configuration/edit/pick-unit.vue";
import SwitchPicker from "@c/device-manage/monitor/device-add/switch-picker.vue";
export default {
    components: {
        ManageAlert,
        BorderInput,
        UploadUnit,
        PickUnit,
        SwitchPicker
    },

    model: {
        prop: "isShowAlert",
        event: "onIsShowAlertChanged"
    },

    props: {
        isShowAlert: Boolean,
        hospitalModel: Object
    },

    data() {
        return {
            hospitalDetailModel: undefined,
            isShowAlertTemp: undefined,
            iconURL: require("@imgs/logo-placeholder.png"),
            currentPickedStandardIndex: 1,
            currentPickedSuggestionIndex: 1,
            forbiddenStatus: 1,//启用
            hospitalTitle: "",
            hospitalLogo: "",
            reportTitle: "",
            reportLogo: "",

        }
    },

    computed: {
        title() {
            return this.hospitalModel ? '编辑机构' : '新增机构'
        },

        initialHospitalTitle() {
            return this.hospitalDetailModel?.hospitalName;
        },

        initialHospitalLogo() {
            if (!this.hospitalDetailModel?.hospitalLogo) {
                return require("@imgs/logo-placeholder.png");
                // return "";
            } else {
                return this.hospitalDetailModel?.hospitalLogo;
            }
        },

        initialReportTitle() {
            return this.hospitalDetailModel?.reportTitle;
        },

        initialReportLogo() {
            if (!this.hospitalDetailModel?.reportLogo) {
                return require("@imgs/logo-placeholder.png");
            } else {
                return this.hospitalDetailModel?.reportLogo;
            }
        },

        initialPickedStandardIndex() {
            return getObjectValues(CHILD_STANDDARD_TYPE_CODE).indexOf(
                this.hospitalDetailModel?.reportStandard
            );
        },

        initialPickedSuggestionIndex() {
            return getObjectValues(REPORT_SUGGESTION_TYPE).indexOf(
                this.hospitalDetailModel?.reportEvaluate
            );
        },

        standDartIndex() {
            return getObjectValues(CHILD_STANDDARD_TYPE_CODE)[
                this.currentPickedStandardIndex
            ];
        },

        suggestionIndex() {
            return getObjectValues(REPORT_SUGGESTION_TYPE)[
                this.currentPickedSuggestionIndex
            ];
        },
    },

    async created() {
        this.isShowAlertTemp = this.isShowAlert
        if (this.hospitalModel) {
            try {
                const params = { id: this.hospitalModel.id }
                this.hospitalDetailModel = await this.$api.getHospitalDetail(params)
                this.hospitalTitle = this.initialHospitalTitle;
                this.hospitalLogo = this.initialHospitalLogo;
                this.reportTitle = this.initialReportTitle;
                this.reportLogo = this.initialReportLogo;
                this.currentPickedStandardIndex = this.initialPickedStandardIndex;
                this.currentPickedSuggestionIndex = this.initialPickedSuggestionIndex;
                this.forbiddenStatus = this.hospitalDetailModel.status ?? 1
            }
            catch (error) {
                this.$toast.showRed(error);
            }

        }
    },

    watch: {
        isShowAlertTemp(value) {
            this.$emit("onIsShowAlertChanged", value)
        },
    },

    methods: {
        isBase64(str) {
            if (str.indexOf('data:') != -1 && str.indexOf('base64') != -1) {
                return true;
            } else {
                return false;
            }
        },

        async onRightButtonClick() {
            if (!this.hospitalTitle) {
                this.$toast.showRed("医院名称不能为空")
                return
            }
            if (!this.reportTitle) {
                this.$toast.showRed("报告抬头不能为空")
                return
            }

            var hospitalLogoStr = ""
            if (!this.isBase64(this.hospitalLogo)) {
                hospitalLogoStr = this.hospitalLogo
            }
            var reportLogoStr = ""
            if (!this.isBase64(this.reportLogo)) {
                reportLogoStr = this.reportLogo
            }

            try {
                const params = {
                    "id": this.hospitalModel?.id ?? null,
                    "hospitalName": this.hospitalTitle,
                    "hospitalType": 1,
                    "hospitalLogo": hospitalLogoStr,
                    "reportLogo": reportLogoStr,
                    "reportTitle": this.reportTitle,
                    "reportStandard": this.standDartIndex,
                    "reportEvaluate": this.suggestionIndex,
                    "status": this.forbiddenStatus
                }
                await this.$api.addHospital(params)
                const isEdit = !!this.hospitalModel
                this.$toast.showGreen(isEdit ? '机构编辑成功' : '机构添加成功');
                this.$emit("onSucceed", isEdit)
            }
            catch (error) {
                this.$toast.showRed(error);
            }


        },

        onCompanyInputInput(value) {
            this.hospitalTitle = value;
        },

        onReportInputInput(value) {
            this.reportTitle = value;
        },

        onStandardListItemClick(selectedIndex) {
            this.currentPickedStandardIndex = selectedIndex;
        },

        onSuggestionListItemClick(selectedIndex) {
            this.currentPickedSuggestionIndex = selectedIndex;
        },

        onForbiddenStatusPicked(isPickRight) {
            this.forbiddenStatus = isPickRight ? 0 : 1
        },

        onCancelClick() {
            this.$emit("onCancelClick");
        },

        onCompanyLogoUploadSucceed(iconURL) {
            this.hospitalLogo = iconURL;
        },

        onReportLogoUploadSucceed(iconURL) {
            this.reportLogo = iconURL;
        },
    },
}
</script>

<style lang="scss" scoped>
.manage-alert-add-organization {
    height: 437px;
    display: flex;

    &-left {
        width: 50%;
        margin-left: rem(24);

        &-input {
            &-key {
                color: rgba($color: black, $alpha: 0.85);
                font-size: rem(14);
                line-height: rem(20);
                margin-top: rem(20);
                margin-bottom: rem(20);

                &-star {
                    color: #F45C50;
                }
            }

            &-value {
                height: rem(32);
                widows: rem(362);
            }
        }

        &-bottom {
            display: flex;

            /deep/.upload-unit {
                flex-direction: column;
                margin-right: rem(40);

                &-key {
                    line-height: rem(20);
                    margin-bottom: rem(20);
                }
            }
        }
    }


    &-right {
        width: 50%;
        margin-left: rem(50);

        &-standard {
            /deep/.pick-unit-right-list {
                width: rem(286);
            }
        }

        &-suggestion {
            /deep/.pick-unit-right-list {
                width: rem(286);
            }
        }

        &-forbidden-status {
            display: flex;
            align-items: center;
        }

        /deep/.pick-unit {
            flex-direction: column;

            &-title {
                line-height: rem(20);
                margin-top: rem(20);
                margin-bottom: rem(20);
            }

            &-right-value {
                margin-top: rem(10);
            }
        }

        /deep/.pick-button {
            color: black;
        }
    }
}
</style>