<template>
    <manage-alert v-model="isShowAlertTemp" :title="title" @onRightButtonClick="onRightButtonClick">
        <template #content>
            <div class="manage-alert-member">
                <div class="manage-alert-member-section">
                    <div class="manage-alert-member-unit">
                        <must-label title="类型"></must-label>
                        <el-select v-model="type" placeholder="请选择" :popper-append-to-body="false">
                            <el-option v-for="item in typeArray" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="manage-alert-member-unit">
                        <must-label title="所属机构"></must-label>
                        <el-select v-model="belongHospitalID" placeholder="请选择" filterable
                            :popper-append-to-body="false">
                            <el-option v-for="item in organizationArray" :key="item.id" :label="item.hospitalName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="manage-alert-member-section">
                    <div class="manage-alert-member-unit">
                        <must-label title="姓名"></must-label>
                        <el-input v-model="memberName" placeholder="请输入"></el-input>
                    </div>
                    <div class="manage-alert-member-unit">
                        <must-label title="手机号"></must-label>
                        <div class="manage-alert-member-unit">
                            <p class="manage-alert-member-unit-mobile" v-if="memberModel">{{ mobile }}</p>
                            <el-input v-else v-model="mobile" placeholder="请输入"></el-input>
                        </div>
                    </div>
                </div>
                <div class="manage-alert-member-section">
                    <div class="manage-alert-member-unit">
                        <must-label title="状态"></must-label>
                        <switch-picker left="启用" right="禁用" v-model="isForbidden"></switch-picker>
                    </div>
                </div>
                <div class="manage-alert-member-bottom">
                    <must-label class="manage-alert-member-bottom-title" title="管理医院"></must-label>
                    <el-transfer class="reshos-transfer" v-model="manageHospitalIDArray" filterable
                        :titles="['未选择', '已选择']" filter-placeholder="请输入搜索内容" :data="organizationArray" :props="{
                            key: 'id',
                            label: 'hospitalName',
                        }" ref="transferRef" @mouseover.native="addTitle"></el-transfer>
                </div>
            </div>
        </template>
    </manage-alert>
</template>

<script>
import ManageAlert from "@c/manage/manage-alert/manage-alert.vue";
import MustLabel from "@c/manage/manage-alert/manage-alert-member/manage-alert-member-must-label.vue";
import SwitchPicker from "@c/manage/manage-alert/manage-alert-member/manage-alert-member-switch-picker.vue";
export default {
    components: {
        ManageAlert,
        MustLabel,
        SwitchPicker,
    },

    model: {
        prop: "isShowAlert",
        event: "onIsShowAlertChanged"
    },

    props: {
        isShowAlert: Boolean,
        memberModel: Object
    },

    data() {
        return {
            memberDetailModel: undefined,
            isShowAlertTemp: undefined,
            typeArray: [{
                value: 1,
                label: '管理员'
            },
            {
                value: 2,
                label: '呼吸教练'
            },
            {
                value: 3,
                label: '全科医师'
            },
            {
                value: 5,
                label: '医院领导'
            },
            {
                value: 6,
                label: '机构管理员'
            },
            {
                value: 7,
                label: '线上咨询'
            },
            {
                value: 8,
                label: '运营专员'
            },
            {
                value: 9,
                label: '机构操作员'
            },
            {
                value: 10,
                label: '商务拓展'
            },
            {
                value: 11,
                label: '官网运维'
            },
            {
                value: 12,
                label: '合作伙伴'
            },
            {
                value: 13,
                label: '销售经理'
            },
            {
                value: 14,
                label: '高级管理员'
            },
            {
                value: 15,
                label: '财务大佬'
            }],
            type: undefined,
            memberName: "",
            mobile: "",
            isForbidden: false,
            organizationArray: [],
            belongHospitalID: undefined,
            manageHospitalIDArray: [],
        }
    },

    computed: {
        title() {
            return this.memberModel ? '编辑人员' : '添加人员'
        },

        initialType() {
            return this.memberDetailModel.roleId
        },

        initialBelongHospitalID() {
            return this.memberDetailModel.hospitalId
        },

        initialMemberName() {
            return this.memberDetailModel.trueName
        },

        initialMobile() {
            return this.memberDetailModel.userName
        },

        initialIsForbidden() {
            return this.memberDetailModel.status != 1
        },

        initialManageHospitalIDArray() {
            return this.memberDetailModel.hospitalIdList?.map((item) => {
                return item.id
            })
        }
    },

    async created() {
        this.isShowAlertTemp = this.isShowAlert
        await this.getOrganizationList()
        await this.getMemberDetail()
    },

    watch: {
        isShowAlertTemp(value) {
            this.$emit("onIsShowAlertChanged", value)
        },

        isForbidden(value) {
            console.log(value)
        }
    },

    methods: {
        onCancelClick() {
            this.$emit("onCancelClick");
        },

        async onRightButtonClick() {
            if (!this.type) {
                this.$toast.showRed("类型不能为空")
                return
            }
            if (!this.belongHospitalID) {
                this.$toast.showRed("所属机构不能为空")
                return
            }
            if (!this.memberName) {
                this.$toast.showRed("姓名不能为空")
                return
            }
            if (!this.mobile) {
                this.$toast.showRed("手机号不能为空")
                return
            }
            if (!this.manageHospitalIDArray.length) {
                this.$toast.showRed("管理医院不能为空")
                return
            }
            try {
                const params = {
                    "id": this.memberModel?.id ?? null,
                    "trueName": this.memberName,
                    "status": this.isForbidden ? 0 : 1,
                    "userName": this.mobile,
                    "roleId": this.type,
                    "hospitalId": this.belongHospitalID,
                    "hospitalIdList": this.manageHospitalIDArray,
                }
                await this.$api.addMember(params)
                const isEdit = !!this.memberModel
                this.$toast.showGreen(isEdit ? '用户编辑成功' : '用户新增成功');
                this.$emit("onSucceed", isEdit)
            }
            catch (error) {
                this.$toast.showRed(error);
            }


        },

        async getOrganizationList() {
            try {
                this.loading = true;
                const params = {
                    "pageNum": 1,
                    "pageSize": 1000
                }
                const response = await this.$api.getOrganizationList(params)
                this.organizationArray = response.list

            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        async getMemberDetail() {
            if (this.memberModel) {
                try {
                    const params = { id: this.memberModel.id }
                    this.memberDetailModel = await this.$api.getMemberDetail(params)
                    this.type = this.initialType
                    this.belongHospitalID = this.initialBelongHospitalID
                    this.memberName = this.initialMemberName
                    this.mobile = this.initialMobile
                    this.isForbidden = this.initialIsForbidden
                    this.manageHospitalIDArray = this.initialManageHospitalIDArray
                }
                catch (error) {
                    this.$toast.showRed(error);
                }
            }
        },

        //为transfer添加标题
        addTitle(e) {
            // 手动给鼠标滑过的元素加一个title
            let target_el = e.target;
            if (target_el.title) return;
            target_el.title = target_el.innerText;
        }
    },
}
</script>

<style lang="scss" scoped>
.manage-alert-member {
    height: rem(550);
    box-sizing: border-box;
    padding: rem(37) rem(84) rem(20) rem(47);
    min-width: rem(800);

    &-section {
        width: calc(100% - rem(27));
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: rem(32);
        margin-left: rem(27);

    }

    &-bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: rem(32);

        &-title {
            margin: 0;
        }

        /deep/.el-transfer-panel__header {
            background-color: white;
            padding-left: 10px;
        }

        /deep/.el-transfer-panel {
            width: rem(240);
        }

        /deep/.el-transfer-panel__header .el-checkbox .el-checkbox__label {
            font-size: 14px;
        }

        /deep/.el-input {
            width: rem(220);
            border-radius: 0;
        }

        /deep/.el-input__inner {
            border-radius: 0px;
            margin-left: -10px;
        }

        /deep/.el-input__icon {
            width: rem(5);
            margin-left: rem(0);
        }
    }

    &-unit {
        height: rem(32);
        display: flex;
        align-items: center;

        &-mobile {
            color: #666666;
            font-size: rem(14);
            width: rem(240);
    
        }

        /deep/.el-select {
            width: rem(240);
            height: 100%;
        }

        /deep/.el-input__icon {
            line-height: rem(32);
        }

        /deep/.el-select .el-input.is-focus .el-input__inner {
            border: 1px solid rgba($color: #000000, $alpha: 0.15);
        }

        /deep/.el-select-dropdown__item {
            font-size: rem(14);
            box-sizing: border-box;
            padding-left: rem(20);
        }

        /deep/.el-input {
            width: rem(240);
            height: 100%;
        }

        /deep/.el-input__inner {
            color: black;
            background-color: white;
            border: none;
            border-radius: rem(4);
            border: 1px solid rgba($color: #000000, $alpha: 0.15);
            font-size: rem(14);
            width: 100%;
            height: 100%;
        }

    }

}
</style>