<template>
    <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
        <div class="organization-cell">
            <cell-unit :style="{ width: '5%' }" :title="organizationID"></cell-unit>
            <cell-unit :style="{ width: '40%' }" :title="organizationName"></cell-unit>
            <cell-unit :style="{ width: '20%' }" :title="count" color="#325FAC">
                <template>
                    <button class="organization-cell-check" style="cursor: pointer" @click="onCheckMemberClick">
                        查看
                    </button>
                </template>
            </cell-unit>
            <cell-unit :style="{ width: '23%' }" :title="forbiddenStatusStr"></cell-unit>
            <div class="organization-cell-operate">
                <cell-unit :style="{ cursor: isAllowDelete ? 'pointer' : '' }"
                    :color="isAllowDelete ? '#F45C50' : '#C1C1C1'"
                    :icon="require(isAllowDelete ? '@imgs/trash.png' : '@imgs/trash-gray.png')" title="删除"
                    @onClick="onDeleteClick"></cell-unit>
                <div class="organization-cell-operate-seprator"></div>
                <cell-unit :style="{ cursor: 'pointer' }" color="#325FAC" :icon="require('@imgs/edit.png')" title="管理"
                    @onClick="onMangeClick"></cell-unit>
            </div>
        </div>
    </list-cell>
</template>

<script>
import CellUnit from "@c/operation-log/common/paging-list/cell-unit.vue";
import ListCell from "@c/operation-log/common/paging-list/list-cell.vue";
import CellUnitItem from "@c/operation-log/common/paging-list/cell-unit.vue";

export default {
    components: {
        ListCell,
        CellUnit,
        CellUnitItem
    },

    props: {
        index: Number,
        model: Object,
        isSelected: Boolean,
    },

    computed: {
        organizationID() {
            return placeholderText(this.model.id)
        },

        organizationName() {
            return placeholderText(this.model.hospitalName)
        },

        count() {
            const organizationAdminNumStr = this.model.organizationAdminNum?.toFixed()
            const organizationOperatorNumStr = this.model.organizationOperatorNum?.toFixed()
            return `${placeholderText(organizationAdminNumStr)}/${placeholderText(organizationOperatorNumStr)}人`
        },

        organization() {
            return placeholderText(this.model.hospitalName);
        },

        forbiddenStatusStr() {
            return this.model.status == 0 ? "禁用" : "启用"
        },

        isAllowDelete() {
            return this.model.isAllowDelete ?? false
        }
    },

    methods: {
        onSelectClick() {
            this.$emit("onSelectClick", this.index);
        },

        onCheckMemberClick() {
            this.$emit("onCheckMemberClick", this.model);
        },

        onDeleteClick() {
            if (!this.isAllowDelete) { return }
            this.$alert.show("机构操作", "是否要删除机构？", () => {
                this.hospitalDeleteRequest();
            });
        },

        onMangeClick() {
            this.$emit("onCheckDetailClick", this.model);
        },

        async hospitalDeleteRequest() {
            try {
                const params = {
                    id: this.model.id
                };
                await this.$api.deleteHospital(params)
                this.$toast.showGreen("机构删除成功");
                this.$emit("onDeleteHospitalSucceed");
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

    },
};
</script>

<style lang="scss" scoped>
.organization-cell {
    height: 100%;
    display: flex;
    align-items: center;

    &-check {
        color: #325FAC;
        font-size: rem(14);
        margin-left: rem(14);
    }

    &-operate {
        width: 12%;
        box-sizing: border-box;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &-seprator {
            background-color: rgb(233, 233, 233);
            height: 14px;
            width: 1px;
        }
    }
}
</style>