<template>
    <div class="system">
        <page-list-container :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage"
            @onPageChange="onPageChange" @onSizeChange="onSizeChange">
            <div>
                <organization-title-bar></organization-title-bar>
                <organization-cell v-for="(item, index) of dataArray" :key="index" :model="item" :index="index"
                    :isSelected="selectedIndex === index" @onSelectClick="onCellSelectClick"
                    @onCheckMemberClick="onCheckMemberClick" @onCheckDetailClick="onEditClick"
                    @onDeleteHospitalSucceed="onDeleteHospitalSucceed"></organization-cell>
            </div>
        </page-list-container>
    </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import OrganizationTitleBar from "@c/manage/organization/organization-title-bar.vue";
import OrganizationCell from "@c/manage/organization/organization-cell.vue";
export default {
    name: "organization",
    components: {
        PageListContainer,
        OrganizationTitleBar,
        OrganizationCell,
    },

    props: {
        keyword: String,
    },

    data() {
        return {
            //列表
            dataArray: [],
            total: 0,
            pageSize: undefined,
            currentPage: 1,
            selectedModel: null,
            selectedIndex: null,
        };
    },

    mounted(){
        this.pageSize = bestPageSizeComputer()
        this.getOrganizationList();
    },

    watch: {
        keyword() {
            this.currentPage = 1
            this.getOrganizationList()
        }
    },

    methods: {
        //cell
        onCellSelectClick: function (index) {
            this.selectedIndex = index;
            this.selectedModel = this.dataArray[index];
        },

        onCheckMemberClick(model) {
            this.$emit("onCheckMemberClick", model)
        },

        onEditClick(model) {
            this.$emit("onEditClick", model)
        },

        onDeleteHospitalSucceed() {
            this.getOrganizationList()
        },

        //分页
        onPageChange(currentPage) {
            this.currentPage = currentPage;
            this.getOrganizationList();
        },

        onSizeChange(size) {
            this.pageSize = size
            this.currentPage = 1
            this.getOrganizationList();
        },

        // method
        async getOrganizationList() {
            this.selectedIndex = null;
            this.selectedModel = null;
            try {
                const params = {
                    "hospitalName": this.keyword,
                    "pageNum": this.currentPage,
                    "pageSize": this.pageSize
                };
                const data = await this.$api.getOrganizationList(params);
                this.dataArray = data.list;
                this.total = data.total;
                this.totalPage = data.pages;

            } catch (error) {
                this.$toast.showRed(error);
            }
        },
    },
};
</script>


<style lang="scss" scoped>
.system {
    font-size: 19px;
    width: 100%;
}
</style>