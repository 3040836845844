<template>
  <div class="switch-picker">
    <button class="switch-picker-button left" :class="[isPickRight ? 'gray' : 'blue']" @click="onLeftClick">
      {{ left }}
    </button>
    <button class="switch-picker-button right" :class="[isPickRight ? 'blue' : 'gray']" @click="onRightClick">
      {{ right }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    left: String,

    right: String,

    initialIsPickRight: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      isPickRight: false,
    };
  },

  created: function () {
    this.isPickRight = this.initialIsPickRight;
  },

  watch: {
    initialIsPickRight(value) {
      this.isPickRight = value
    }
  },

  methods: {
    onLeftClick: function () {
      this.isPickRight = false;
      this.$emit("onPick", this.isPickRight);
    },

    onRightClick: function () {
      this.isPickRight = true;
      this.$emit("onPick", this.isPickRight);
    },
  },
};
</script>

<style lang="scss" scoped>


.switch-picker {
  width: rem(119);
  height: rem(32);
  display: flex;

  &-button {
    font-size: rem(14);
    width: rem(60);
    height: rem(32);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.left {
  border-top-left-radius: rem(4);
  border-bottom-left-radius: rem(4);
}

.right {
  border-top-right-radius: rem(4);
  border-bottom-right-radius: rem(4);
}

.blue {
  color: white;
  background-color: #325FAC;
  border: rem(1) solid #325FAC;
}

.gray {
  color: rgba($color: black, $alpha: 0.65);
  background-color: white;
  border: rem(1) solid #e8e8e8;
}
</style>