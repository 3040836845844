<template>
    <div class="manage">
        <filter-bar :itemArray="['机构列表', '用户列表']" :initialSelectedIndex="selectedComponentIndex" :buttonTitle="buttonTitle"
            :keywordTitle="keywordTitle" :keyword="keyword" @onItemSelected="onItemSelected" @onButtonClick="onButtonClick"
            @onSearchClick="onKeywordSearch" />
        <component ref="list" :is="currentComponent" :keyword="keyword" @onCheckMemberClick=onCheckMemberClick
            @onEditClick="onEditClick"></component>
        <manage-alert-organization v-if="isShowOrganizationAlert" v-model="isShowOrganizationAlert"
            :hospitalModel="selectedHospitalModel" @onSucceed="onOrganizationSucceed"></manage-alert-organization>
        <manage-alert-member v-if="isShowMemberAlert" v-model="isShowMemberAlert" :memberModel="selectedMemberModel"
            @onSucceed="onMemberSucceed"></manage-alert-member>
        <el-drawer :visible.sync="isShowDrawer" :with-header="false" size="680" destroy-on-close>
            <manage-member-panel ref="panel" v-model="isShowDrawer" :hospitalModel="selectedHospitalModel" @onEdit="onMemberPanelEdit"
                @onAdd="onMemberPanelAdd"></manage-member-panel>
        </el-drawer>
    </div>
</template>
  
<script>
import FilterBar from "@c/operation-log/common/filter-bar/filter-bar.vue";
import Member from "@c/manage/member/member.vue";
import Organization from "@c/manage/organization/organization.vue";
import ManageAlertOrganization from "@c/manage/manage-alert/manage-alert-organization.vue";
import ManageAlertMember from "@c/manage/manage-alert/manage-alert-member/manage-alert-member.vue";
import ManageMemberPanel from "@c/manage/manage-member-panel.vue";

export default {
    components: {
        FilterBar,
        Member,
        Organization,
        ManageAlertOrganization,
        ManageAlertMember,
        ManageMemberPanel
    },

    data() {
        return {
            selectedComponentIndex: 0,
            duration: undefined,
            buttonTitle: "新增机构",
            keywordTitle: "机构名称",
            keyword: "",
            selectedHospitalModel: undefined,
            selectedMemberModel: undefined,
            isShowOrganizationAlert: false,
            isShowMemberAlert: false,
            isShowDrawer: false
        };
    },

    computed: {
        currentComponent() {
            switch (this.selectedComponentIndex) {
                case 0:
                    return "organization";
                default:
                    return "member";
            }
        }
    },

    watch: {
        currentComponent(newValue) {
            if (newValue == "organization") {
                this.buttonTitle = "新增机构"
                this.keywordTitle = "机构名称";
            } else {
                this.buttonTitle = "新增用户"
                this.keywordTitle = "姓名/手机号/所属机构";
            }
        },

    },

    methods: {
        //filter-bar
        onItemSelected(index) {
            this.selectedComponentIndex = index;
            this.keyword = ""
        },

        onButtonClick() {
            switch (this.buttonTitle) {
                case "新增机构":
                    this.selectedHospitalModel = undefined
                    this.isShowOrganizationAlert = true
                    break;
                case "新增用户":
                    this.selectedMemberModel = undefined
                    this.isShowMemberAlert = true
                    break;
                default:
                    break;
            }
        },

        onKeywordSearch(keyword) {
            this.keyword = keyword;
        },

        //member-panel
        onMemberPanelEdit(memberModel) {
            this.selectedMemberModel = memberModel
            this.isShowMemberAlert = true
        },

        onMemberPanelAdd() {
            this.selectedMemberModel = undefined
            this.isShowMemberAlert = true
        },

        //cell
        onCheckMemberClick(hospitalModel) {
            this.isShowDrawer = true
            this.selectedHospitalModel = hospitalModel

        },

        onEditClick(model) {
            switch (this.buttonTitle) {
                case "新增机构":
                    this.selectedHospitalModel = model
                    this.isShowOrganizationAlert = true
                    break;
                case "新增用户":
                    this.selectedMemberModel = model
                    this.isShowMemberAlert = true
                    break;
                default:
                    break;
            }
        },

        onOrganizationSucceed(isEdit) {
            this.isShowOrganizationAlert = false
            const organizationView = this.$refs.list
            if (isEdit) {
                organizationView.getOrganizationList()
            } else {
                this.keyword = "" //实践证明不会触发属性观察器
                organizationView.currentPage = 1
                organizationView.getOrganizationList()
            }
        },

        onMemberSucceed(isEdit) {
            this.isShowMemberAlert = false
            switch (this.currentComponent) {
                case 'member':
                    const memberView = this.$refs.list
                    if (isEdit) {
                        memberView.getMemberList()
                    } else {
                        this.keyword = "" //实践证明不会触发属性观察器
                        memberView.currentPage = 1
                        memberView.getMemberList()
                    }
                    this.onOrganizationSucceed(isEdit)
                    break;
                case 'organization':
                    const panel = this.$refs.panel
                    panel.getMenberList()
                    this.onOrganizationSucceed(isEdit)
                    break;
                default:
                    break;
            }
        }
    },
};
</script>
  
<style lang="scss" scoped>


.manage {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    padding: rem(0) rem(33) rem(0) rem(36);
}
</style>