<template>
    <div class="main-hospital-panel">
        <p class="main-hospital-panel-title">{{ placeholderText(hospitalModel.hospitalName) }}</p>
        <el-input v-model="keyword" placeholder="请输入姓名搜索"></el-input>
        <div class="main-hospital-panel-section-header">
            <p style="width:15%">姓名</p>
            <p style="width:20%">手机号</p>
            <p style="width:20%">类型</p>
            <p style="width:20%">状态</p>
            <p style="width:25%">操作</p>
        </div>
        <div class="main-hospital-panel-list">
            <div v-if="dataArray.length" class="main-hospital-panel-list-content">
                <div v-if="dataArray.length" class="main-hospital-panel-list-content-cell"
                    v-for="(item, index) in dataArray" :key="index">
                    <p style="width:15%">{{ placeholderText(item.trueName) }}</p>
                    <p style="width:20%">{{ placeholderText(item.userName) }}</p>
                    <p style="width:20%">{{ placeholderText(item.userTypeDesc) }}</p>
                    <p style="width:20%">{{ item.status ? '启用' : '禁用' }}</p>
                    <button style="width:25%" @click="onEdit(item)">{{ placeholderText(item.hospitalCount)
                    }}家机构（修改）</button>
                </div>
            </div>
            <p v-else class="main-hospital-panel-list-empty">暂无数据</p>
        </div>
        <div class="main-hospital-panel-bottom">
            <button class="main-hospital-panel-bottom-cancel" @click="onCancel">取消</button>
            <button class="main-hospital-panel-bottom-confirm" @click="onAdd">新增人员</button>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "isShowDrawer",
        event: "onisShowDrawerChaneed"
    },

    props: {
        isShowDrawer: Boolean,
        hospitalModel: Object
    },

    data() {
        return {
            dataArray: [],
            keyword: ""
        }
    },

    mounted() {
        this.getMenberList()

    },

    watch: {
        keyword() {
            this.getMenberList()
        }
    },

    methods: {
        placeholderText(str) {
            return placeholderText(str)
        },

        async getMenberList() {
            try {
                const params = {
                    "hospitalId": this.hospitalModel.id,
                    "keyWord": this.keyword,
                    "pageNum": 1,
                    "pageSize": 100
                }
                const response = await this.$api.getMenberList(params)
                this.dataArray = response.list
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        onCancel() {
            this.$emit("onisShowDrawerChaneed", false)
        },

        onEdit(memberModel) {
            this.$emit("onEdit", memberModel)
        },

        onAdd() {
            this.$emit("onAdd")
        }
    }
}
</script>

<style lang="scss" scoped>


.main-hospital-panel {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: rem(40);
    display: flex;
    flex-direction: column;

    &-title {
        color: #444C72;
        font-size: rem(24);
        line-height: rem(33);
    }

    /deep/.el-input__inner {
        background-color: #F7F7F7;
        border: none;
        border-radius: rem(10);
        font-size: rem(14);
        margin-top: rem(20);
    }

    &-section-header {
        background-color: #325FAC;
        height: rem(36);
        border-radius: rem(5);
        margin-top: rem(14);
        display: flex;
        align-items: center;

        >p {
            color: white;
            font-size: rem(14);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-list {
        flex: 1 1 auto;
        height: 0;
        overflow: hidden;
        overflow-y: auto;

        &-content {
            &-cell {
                font-size: rem(14);
                line-height: rem(20);
                margin-top: rem(20);
                margin-bottom: rem(20);
                display: flex;
                align-items: center;

                >p {
                    color: #262626;
                    font-size: rem(14);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                >button {
                    color: #325FAC;
                    font-size: rem(14);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &-empty {
            font-size: rem(14);
            text-align: center;
            margin-top: 20px;

        }
    }

    &-bottom {
        box-sizing: border-box;
        padding-top: rem(20);
        height: rem(60);
        display: flex;
        justify-content: space-between;

        &-cancel {
            background-color: #F7F7F7;
            color: #444C72;
            font-size: rem(14);
            border-radius: rem(5);
            width: rem(72);
            height: rem(44);
            margin-right: rem(20);
        }

        &-confirm {
            background-color: #325FAC;
            color: white;
            font-size: rem(14);
            border-radius: rem(5);
            width: rem(508);
            height: rem(44);
        }
    }

}
</style>