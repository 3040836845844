<template>
    <div class="system">
        <page-list-container :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage" @onPageChange="onPageChange" @onSizeChange="onSizeChange">
            <div>
                <member-title-bar></member-title-bar>
                <member-cell v-for="(item, index) of dataArray" :key="index" :model="item" :index="index"
                    :isSelected="selectedIndex === index" @onSelectClick="onCellSelectClick"
                    @onEditClick="onEditClick"></member-cell>
            </div>
        </page-list-container>
    </div>
</template>
  
<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import MemberTitleBar from "@c/manage/member/member-title-bar.vue";
import MemberCell from "@c/manage/member/member-cell.vue";
export default {
    name: "organization",
    components: {
        PageListContainer,
        MemberTitleBar,
        MemberCell,
    },

    props: {
        keyword: String,
    },

    data() {
        return {
            //列表
            dataArray: [],
            total: 0,
            pageSize: undefined,
            currentPage: 1,
            selectedModel: null,
            selectedIndex: null,
        };
    },

    mounted(){
        this.pageSize = bestPageSizeComputer()
        this.getMemberList();
    },

    watch: {
        keyword() {
            this.currentPage = 1
            this.getMemberList()
        }
    },

    methods: {
        //cell
        onCellSelectClick: function (index) {
            this.selectedIndex = index;
            this.selectedModel = this.dataArray[index];
        },

        onEditClick(model) {
            this.$emit("onEditClick", model)
        },

        //分页
        onPageChange(currentPage) {
            this.currentPage = currentPage;
            this.getMemberList();
        },

        onSizeChange(size) {
            this.pageSize = size
            this.currentPage = 1
            this.getMemberList();
        },

        // method
        async getMemberList() {
            this.selectedIndex = null;
            this.selectedModel = null;
            try {
                const params = {
                    "keyWord": this.keyword,
                    "pageNum": this.currentPage,
                    "pageSize": this.pageSize
                };
                const data = await this.$api.getMenberList(params);
                this.dataArray = data.list;
                this.total = data.total;
                this.totalPage = data.pages;
            } catch (error) {
                this.$toast.showRed(error);
            }
        },
    },
};
</script>
  
  
<style lang="scss" scoped>


.system {
    font-size: 19px;
    width: 100%;
}
</style>