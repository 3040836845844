<template>
    <div class="switch-picker">
        <button class="switch-picker-button left" :class="[isPickRight ? 'gray' : 'blue']" @click="onLeftClick">
            {{ left }}
        </button>
        <button class="switch-picker-button right" :class="[isPickRight ? 'blue' : 'gray']" @click="onRightClick">
            {{ right }}
        </button>
    </div>
</template>
  
<script>
export default {
    model: {
        prop: "isPickRight",
        event: "onIsPickRightChanged"
    },

    props: {
        left: String,

        right: String,

        isPickRight: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onLeftClick() {
            this.$emit("onIsPickRightChanged", false);
        },

        onRightClick() {
            this.$emit("onIsPickRightChanged", true);
        },
    },
};
</script>
  
<style lang="scss" scoped>


.switch-picker {
    width: rem(119);
    height: rem(32);
    display: flex;

    &-button {
        font-size: rem(14);
        width: rem(60);
        height: rem(32);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.left {
    border-top-left-radius: rem(4);
    border-bottom-left-radius: rem(4);
}

.right {
    border-top-right-radius: rem(4);
    border-bottom-right-radius: rem(4);
}

.blue {
    color: white;
    background-color: #325FAC;
    border: rem(1) solid #325FAC;
}

.gray {
    color: rgba($color: black, $alpha: 0.65);
    background-color: white;
    border: rem(1) solid #e8e8e8;
}
</style>